.s-skills {
  position: relative;
    padding: 50px 0;

    .container {
      display: flex;

      @include tabletPortrait {
        flex-direction: column;
      }
    }

    .bg{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 38%;
        background-color: rgba($color-blue, .10);
        z-index: -1;

        @include tabletPortrait {
          display: none;
        }
      }

      &-title {
        display: flex;
        align-items: flex-end;
    
        span {
          left: 50px;
        }
    
        hr {
          width: 67px;
          border: 1px solid $color-black-4;
          margin: auto 23px auto 0;
        }
      }

    .description{
        flex: 1 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3{
            display: flex;
            align-items: flex-end;
            margin-left: 84%;
            white-space:nowrap;

            @include tabletPortrait {
              margin-left: initial;
            }

            hr{
                width: 30px;
                border: 1px solid $color-black-4;
                margin: auto 23px auto 0;
            }
        }

        p{
            width: 50%;
            margin: auto;
            color: $color-black-4;
            font-size: 20px;
            font-weight: normal;
            line-height: 40px;

            @include tabletPortrait {
              display: none;
            }
        }
    }

    .icons{
        flex: 1 75%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 78px 0 78px 40px;

        .icon{
            margin: 30px;
            display: flex;
    align-items: center;
    justify-content: center;
        }
    }
}