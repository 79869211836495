.s-services {
  padding: 50px 0;
  background-color: $color-grey;

  .container{
      display: flex;
      justify-content: center;
      align-items: center;

      @include tabletPortrait {
        flex-direction: column;
        align-items: flex-start;
      }

      .service{
          flex: 1;
          padding-left: 10px;

          &:not(:first-child) {

            @include tabletPortrait {
              margin-top: 20px;
            }
          }

          ul{
              margin-top: 10px;

              li{
                  margin-top: 20px;
              }
          }
      }
  }
}