.s-myJob {
  padding-top: 80px;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: calc(100% - 80px);

    @include tabletPortrait {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-left {
    flex: 1;
  }

  &-title {
    display: flex;
    align-items: flex-end;

    span {
      left: 80px;
    }

    hr {
      width: 67px;
      border: 1px solid $color-black-4;
      margin: auto 23px auto 0;
    }
  }

  &-text {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    line-height: 40px;

    @include tabletPortrait {
      width: 100%;
    }
  }

  &-right {
    flex: 1;

    @include tabletPortrait {
      margin-top: 20px;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 20px;
  }

  &-arrow {
    display: flex;
    margin-left: 30px;
    padding: 10px 15px;
    background-color: $color-blue;
    border-radius: 100%;
  }
}