.s-vision {
  position: relative;
    padding: 50px 0;

    &-btnWrap {
      display: flex;
      margin-top: 30px;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      padding: 5px;
      background-color: $color-white;
      border-radius: 100%;
      margin-left: 10px;
  
      span {
        line-height: 0;
      }
    }

    &-cv {
      margin-top: 30px;
      padding: 12px 30px;
      background-color: $color-blue;
      color: $color-white;
      white-space:nowrap;
      width: fit-content;
  }

    .bg{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-color: rgba($color-blue, .10);
        z-index: -1;

        @include tabletPortrait {
          width: 100%;
          height: 50%;
          bottom: initial;
          top: 0;
        }
    }

    .title{
        display: flex;
        flex-direction: column;
        height: fit-content;

        h3{
            font-weight: 600;
        }
    }

    .description{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 60px;

        p{
          font-size: 20px;
          line-height: 40px;

            @include tabletPortrait {
              text-align: center;
              width: 100%;
            }
        }
    }
}