@mixin tablet {
  @media only screen and (max-width: $mq-tab) {
    @content;
  }
}

@mixin tabletOnly {
  @media (max-width: $mq-tab) and (min-width: $mq-mob) {
    @content;
  }
}

@mixin tabletMin {
  @media (min-width: $mq-tab) {
    @content;
  }
}

@mixin tabletPortrait {
  @media (max-width: $mq-tab-portrait) {
    @content;
  }
}

@mixin tabletPortraitMin {
  @media (min-width: $mq-tab-portrait) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mq-mob) {
    @content;
  }
}

@mixin mobileMin {
  @media (min-width: $mq-mob) {
    @content;
  }
}

@mixin mobilePortrait {
  @media (max-width: $mq-mob-portrait) {
    @content;
  }
}

@mixin mobilePortraitMin {
  @media (min-width: $mq-mob-portrait) {
    @content;
  }
}

@mixin media($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}

@mixin mediaMin($max-width) {
  @media (min-width: $max-width) {
    @content;
  }
}

@mixin mediaBetween($min-width, $max-width) {
  @media (max-width: $max-width) and (min-width: $min-width) {
    @content;
  }
}
