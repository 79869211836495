.p-projects {
  padding: 90px 0 35px;
  overflow: hidden;

  .container {
    position: relative;
  }

  &-logo {
    position: absolute;
    right: -100px;
    top: -150px;
    width: 747.54px;
    height: 769.89px;
    opacity: .5;
    transform: rotate(15deg);
    z-index: -1;

    svg {
      height: 100%;
      width: 100%;
    }
}

  &-list {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 27px;
    row-gap: 27px;

    @include tabletPortrait {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-item {
    position: relative;
    height: fit-content;
    opacity: 0;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($color-black, .5);
      border-radius: 5px;
      opacity: 0;
      transition: all 250ms ease-in-out;
      pointer-events: none;
    }
    

    &:nth-child(even) {
      transform: translateY(50px);

      @include tabletPortrait {
        transform: initial;
      }
    }

    &:hover {

      &::after {
        opacity: 1;
      }
    }
  }

  &-imgWrapper {
    display: block;
    max-width: initial;

    .i-desktop {
      display: block;
      width: 100%;
      line-height: 0;
    }
  
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-itemInfos {
    position: absolute;
    bottom: 40px;
    left: 25px;
    z-index: 1;
    opacity: 0;
    transform: translateX(-200px);
    transition: all 250ms ease-in-out;
    
    p {
      color: $color-white;
      font-weight: 300;
    }
  }

  &-item:hover &-itemInfos {
    opacity: 1;
    transform: initial;
  }

  &-itemTitle {
    font-size: 24px;
    line-height: 28px;
  }

  &-itemType {
    font-size: 12px;
    line-height: 14px;
  }
}