/*
100 thin
200 extra-light
300 light
400 regular
500 medium
600 semi-bold
700 bold
800 extra-bold
900 black
*/

/* ROBOTO */

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: normal;
	font-weight: 900;

	src: url('../../font/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: italic;
	font-weight: 900;
	src: url('../../font/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;

	src: url('../../font/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: italic;
	font-weight: 700;
	src: url('../../font/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	src: url('../../font/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: italic;
	font-weight: 500;
	src: url('../../font/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	src: url('../../font/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: italic;
	font-weight: 400;
	src: url('../../font/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	src: url('../../font/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: italic;
	font-weight: 300;
	src: url('../../font/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: normal;
	font-weight: 100;
	src: url('../../font/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
	font-display: fallback;
	font-family: Roboto;
	font-style: italic;
	font-weight: 100;
	src: url('../../font/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}