.s-welcome {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-top: 70px;

  .container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tabletPortrait {
      flex-direction: column;
      justify-content: center;
    }

    > div {

      @include tabletPortraitMin {
        width: 50%;
      }
    }
  }

  &.home {
      background-color: rgba($color-blue,.65);
  }

  &.about {
    background-color: $color-white;

    *{
        color: $color-black-4;
    }
  }

  &.services {
    height: auto;
    padding: 105px 0 75px;
    background-color: $color-white;

    *{
        color: $color-black-4;
    }
  }

  &-logo {
      position: absolute;
      right: -100px;
      top: -150px;
      width: 747.54px;
      height: 769.89px;
      opacity: .5;
      transform: rotate(15deg);

      svg {
        height: 100%;
        width: 100%;
      }
  }

  &.services &-logo {
    width: 547.54px;
    height: 569.89px;
  }

  &-title {
    font-weight: 400;
      color: $color-white;
      z-index: 1;
  }

  &-subtitle {
    margin-top: 20px;
      color: $color-white;
      z-index: 1;
  }

  .services &-subtitle {
    margin-top: 20px;
    font-weight: 400;
    font-size: 24px;
    line-height: 154.4%;
    text-transform: initial;
  }

  &-scroll {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      padding-top: 8px;
      border-top: solid 1px $color-white;
      color: $color-white;
      font-size: 12px;
      text-transform: uppercase;
      transform: rotate(-90deg);
  }

  .about &-scroll {
    border-top: solid 1px $color-black-4;
  }

  .services &-scroll {
    display: none;
  }
}