.c-cursor {
  height: 100%;
  width: 100%;

  @include tabletPortrait {
    display: none;
  }
  
  &-cursor {
    position: fixed;
    top: -5px;
    left: -5px;
    width: 10px;
    height: 10px;
    background-color: $color-black-4;
    border-radius: 50%;
    pointer-events: none;
    z-index: 999;
  }
  
  &-border {
    --size: 50px;
    position: fixed;
    top: calc(var(--size) / -2);
    left: calc(var(--size) / -2);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    box-shadow: 0 0 0 1px $color-black-4;
    pointer-events: none;
    transition: top 0.15s ease-out, left 0.15s ease-out, width 0.15s ease-out,
    height 0.15s ease-out, background-color 0.15s ease-out;
    z-index: 998;
  }
}