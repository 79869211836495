.s-lastProjects {
  padding-top: 80px;

  &-title {
    display: flex;
    align-items: flex-end;

    span {
      left: 80px;
    }

    hr {
      width: 67px;
      border: 1px solid $color-black-4;
      margin: auto 23px auto 0;
    }
  }

  &-description,
  &-infos {
    flex: 1;
  }

  &-itemWrapper:nth-child(odd) &-item {
    flex-direction: row-reverse;
      
    .s-lastProjects-bg {
        left: initial;
        right: -$containerMargin;
    }

    .s-lastProjects-description {

      p {
        text-align: left;
        margin-left: 0;
      }

      a{
        width: fit-content;
        margin: 50px auto 0 0;
      }
    }

    .s-lastProjects-itemTitle {

      h4 {
        flex-direction: row-reverse;
      }

      hr {
        margin: auto 0 auto 23px;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 60px 0 0;
    padding: 70px 0 110px;
    width: 100%;

    @include tablet {
      flex-direction: column !important;
    }
  }

  &-bg {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    height: 90%;
    width: 30%;
    background-color: rgba($color-blue, .10);
    z-index: -1;
    left: -$containerMargin;

    @include tablet {
      width: 50%;
    }
  }

  &-itemTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    h4{
      display: flex;
      align-items: flex-end;

      hr {
        width: 30px;
        border: 1px solid $color-black-4;
        margin: auto 23px auto 0;
      }
    }
  }

  &-infos {

    @include tabletPortrait {
      width: 100%;
    }
  }

  &-imgWrapper {
    display: block;
    margin-top: 60px;
    max-width: 85%;

    @include tabletPortrait {
      max-width: initial;

      span {
        display: block;
        width: 100%;
        line-height: unset;
      }
  
      svg {
        width: 100%;
        height: 100%;
      }
    }
  
  }

  &-imgOverlay {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: $color-grey;
    width: 100%;
    height: 100%; 
    transform: skewX(30deg) scale(1.5);
    z-index: 1;
  }

  &-description {

    @include tablet {
      margin-top: 50px;
    }

    p{
      text-align: right;
      width: 70%;
      font-size: 20px;
      line-height: 40px;
      margin-left: auto;
      margin-right: 0;

      @include tablet {
        width: 100%;
      }
    }

    a{
      width: fit-content;
      margin: 50px 0 0 auto;
    }
  }

  &-btn {
    width: fit-content;
    margin: 60px auto;
  }

  .i-desktop {
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    } 
  }
}