$color-black: #000;
$color-black-2: #535353;
$color-black-3: #969696;
$color-black-4: #424242;
$color-grey: #E6E6E6;
$grey-greyLight: #EEEEEE;
$color-red: #FF4848;
$color-blue: #4C29FF;
$color-blue-2: #a593ff;
$color-cyan: #7CFCE7;
$color-white: #FFF;
$color-green:#32CD32;