.c-header {
  position: fixed;
  width: 100%;
  padding: 10px 0;
  z-index: 20;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-logo {
      height: 38px;
  }

  &-btnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
      margin: 0 8px;
    }
  }

  &-translateBtnWrapper,
  &-translateBtn {
    color: $color-black-4;
  }
  
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    padding: 5px;
    background-color: $color-blue;
    border-radius: 100%;
    margin-left: 10px;

    &.is-white {
      background-color: $color-white;
    }

    span {
      line-height: 0;
    }
  }

  &-burgerIcon {
    line-height: 0;

    svg {
      -webkit-tap-highlight-color: transparent;
      transition: transform 400ms;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &.is-active {

      svg {
        transform: rotate(45deg);
      }

      .top {
        stroke-dashoffset: -98px;
      }

      .bottom {
        stroke-dashoffset: -138px;
      }
    }

    .line {
      fill:none;
      transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
      stroke-width:5.5;
      stroke-linecap:round;
    }

    .top {
      stroke-dasharray: 40 139;
    }
    .bottom {
      stroke-dasharray: 40 180;
    }
  }
}