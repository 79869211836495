.p-contact {
  position: relative;
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  padding: 150px 0 50px;

  @include tabletPortrait {
    height: auto;
  }

  .container {
    max-width: 800px;
  }

  &-logo {
    position: absolute;
    right: -100px;
    top: -150px;
    width: 747.54px;
    height: 769.89px;
    opacity: .5;
    transform: rotate(15deg);
    z-index: -1;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &-title {
    text-align: center;
    font-weight: 600;
  }

  &-text {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }

  form {
    margin-top: 20px;
  }

  &-inputGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tabletPortrait {
      flex-direction: column;
    }

    fieldset {
      width: 45%;

      @include tabletPortrait {
        width: 100%;
      }
    }
  }

  &-label {
    color: $color-blue;
    opacity: .3;
    margin-bottom: 10px;

    @include tabletPortrait {
      opacity: 1;
    }
  }

  &-input,
  &-textarea {
    padding: 8px 8px 8px 0;
    width: 100%;
    border: none;
    border-bottom: solid 1px $color-blue;
    background-color: transparent;
    border-radius: 0 !important;
  }

  fieldset {
    margin-top: 20px;
  }

  &-textarea {
    font-family: Arial, Helvetica, sans-serif;
    height: 100px;
  }

  &-btn {
    margin: 20px 0 0 auto;
  }
}