.p-project {
  padding: 90px 0 35px;

  &-header {
    margin-top: 20px;
    padding: 50px 0;
    background-color: rgba($color-blue, .60);
  }

  &-type {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: $color-white;
  }

  &-title {
    margin-top: 15px;
    text-align: center;
    color: $color-white;
  }

  &-content {
    display: flex;
    justify-content: center;
    padding: 40px 0; 

    @include tablet {
      flex-direction: column-reverse;
    }
  }

  &-infos {
    flex: 1 40%;
  }

  &-description {
    color: $color-black-4;
    font-size: 20px;
    line-height: 40px;

    @include tablet {
      margin-top: 20px;
    }

    @include tabletPortrait {
      line-height: 32px;
    }
  }

  &-technos {
    display: flex;
    margin-top: 20px;
    color: $color-black-4;
    font-size: 20px;
    line-height: 40px;

    ul {
      margin-left: 20px;
      padding-left: 20px;
      border-left: solid 1px $color-black-4;
    }
  }

  &-imgWrapper {
    flex: 1 60%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &::after{
      content: '';
      position: absolute;
      bottom: -30px;
      right: 20px;
      display: block;
      width: 80%;
      height: 100%;
      background-color: rgba($color-blue, .10);
      z-index: -1;

      @include tablet {
        content: initial;
      }
    }

    @include tabletPortrait {
      span {
        display: block;
        width: 100%;
        line-height: unset;
      }
  
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-link {
    display: flex;
    align-items: center;
    color: $color-blue;
    text-transform: uppercase;

    span {
      margin-right: 8px;
    }
  }

  &-back {
    display: flex;
    align-items: center;
    color: $color-blue;
    text-transform: uppercase;

    span {
      margin-left: 8px;
    }
    
    svg {
      transform: rotate(180deg);
    }
  }
}