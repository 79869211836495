.display-f {
  display: flex;
}

.cursor-i {
	cursor: initial;
}

.cursor-p {
	cursor: pointer;
}

.display-b {
	display: block;
}

.display-i {
	display: initial !important;
}

.display-f {
	display: flex;
}

.display-f-c {
	display: flex;
	align-items: center;
}

.display-f-c-b {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.display-f-b {
	display: flex;
	justify-content: space-between;
}

.display-f-c-a {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.display-f-c-c {
	display: flex;
	align-items: center;
	justify-content: center;
}

.display-f-c-s {
	display: flex;
	align-items: flex-start;
}

.display-fc {
	display: flex;
	flex-direction: column;
}

.display-fc-c {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.display-fc-c-c {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.display-f-s-b {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.display-f-e-b {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.display-f-e {
	display: flex;
	justify-content: flex-end;
}

.display-f-stretch-s {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
}

.display-f-center-e {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.display-fw {
	display: flex;
	flex-wrap: wrap;
}

.display-n {
  display: none !important;
}

.display-n--tb {

	@include tablet {
		display: none !important;
	}
}

.display-n--tb--min {

	@include tabletMin {
		display: none !important;
	}
}

.display-n--tb-p {

	@include tabletPortrait {
		display: none !important;
	}
}

.display-n--tb-p-min {

	@include tabletPortraitMin {
		display: none !important;
	}
}

.display-n--mb {

	@include mobile {
		display: none !important;
	}
}

.font-weight-600 {
	font-weight: 600 !important;
}

.font-weight-700 {
	font-weight: 700 !important;
}

.margin-0 {
	margin: 0 !important;
}

.margin-0-auto {
	margin: 0 auto;
}

.margin-t-0 {
	margin-top: 0 !important;
}

.opacity-50 {
	opacity: .5;
}

.overflow-h {
	overflow: hidden;
}

.overflow-s {
	overflow: scroll;
}

.position-r {
	position: relative;
}

.text-align-c {
	text-align: center;
}

.text-align-l {
	text-align: left;
}

.text-align-r {
	text-align: right;
}

.text-transform-u {
  text-transform: uppercase;
}

.text-transform-u-fl {

	&:first-letter {
		text-transform: uppercase;
	}
}

.text-transform-l {
  text-transform: lowercase;
}

.text-align-c {
  text-align: center;
}

.text-decoration-u {
  text-decoration: underline;
}

.show--tb {

	@include tabletMin {
		display: none !important;
	}
}

.show--tb-p {

	@include tabletPortraitMin {
		display: none !important;
	}
}

.show--mb {

	@include mobileMin {
		display: none !important;
	}
}

.white-space-n {
	white-space: nowrap;
}

.height-100 {
  height: 100%;
}

.height-100-vh {
  height: 100vh;
}

.width-100 {
  width: 100%;
}
