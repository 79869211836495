.i-desktop {
  display: block;
  position: relative;
  width: fit-content;
  line-height: initial;

  img {
    width: 98%;
    height: 89%;
    position: absolute;
    bottom: 50%;
    left: 50%;
    object-fit: contain;
    transform: translate(-50%, 53%);
    border-radius: 7px;
  }
}