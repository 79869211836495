.c-menu{
    position: fixed;
    bottom: 0;
    width: 0;
    height: 100vh;
    background-color: $color-blue-2;
    z-index: 10;

    &-nav {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
    }

    &-link {
        margin-top: 20px;
        margin-left: -20px;
        font-weight: 700;
        opacity: 0;
        color: $color-white;
        transform: translateX(-200%);
        transition: opacity 500ms, margin-left 500ms;

        @include tabletPortrait {
          margin-left: 0;
          opacity: 1 !important;
        }

        &:hover {
          opacity: 1 !important;
          
          @include tabletPortraitMin {
            margin-left: 0;
  
            span {
              opacity: 1;
            }
          }
        }

        span {
          margin-right: 5px;  
          font-size: 15px;
          font-weight: 400;
          opacity: 0;
          transition: opacity 500ms;
          vertical-align: super;

          @include tabletPortrait {
            opacity: 1;
          }
        }
    }
}