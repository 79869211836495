.ui-a {
	display: block;
}

.ui-button {
	display: block;
	padding: 28px 53px;
	background-color: $color-blue;
	color: $color-white;
	text-align: center;

	&.is-valid {
		cursor: pointer;
		background-color: $color-green;
	}

	&.is-error {
		background-color: $color-red;
	}
}

.ui-checkbox {
	display: block;
}

.ui-fieldset {
	display: block;
}

.ui-em {
	font-style: italic;
}

.ui-h1 {
	display: block;
	color: $color-black-4;
	font-family: Roboto;
	font-size: 40px;
	line-height: 47px;
	text-transform: uppercase;

	@include tabletPortrait {
		font-size: 30px;
		line-height: 37px;
	}

	&.is-big {
    font-size: 80px;
    line-height: 90px;
    font-weight: bold;

		@include tablet {
			font-size: 45px;
			line-height: 55px;
		}
	}

	&.is-medium {
		font-size: 64px;
		line-height: 75px;
    font-weight: bold;
		
		@include tabletPortrait {
			font-size: 40px;
			line-height: 47px;
		}
	}
}

.ui-h2 {
	display: block;
}

.ui-h3 {
	display: block;
}

.ui-h4 {
	display: block;
}

.ui-h5 {
	display: block;
}

.ui-h6 {
	display: block;
}

.ui-img {
	display: block;
}

.ui-input {
	display: block;
}

.ui-label {
	display: block;
}

.ui-p {
	display: block;
}

.ui-radio {
	display: block;
}

.ui-select {
	@extend .ui-input;
}

.ui-textarea {
	@extend .ui-input;
}

.ui-ol {
	display: block;

	li {
		display: block;
	}
}

.ui-progress {
	display: block;
}

.ui-ul {
	display: block;

	li {
		display: block;
	}
}
